/*
 * 업무구분: 고객
 * 화 면 명: MSPCM201M
 * 화면설명: 세미나 활동(FC)
 * 접근권한: FC
 * 작 성 일: 2023.03.22
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="세미나 활동" :show-title="true" type="subpage" action-type="search" @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenMSPCM116P"
   :topButton="true" :topButtonBottom="topButtonBottom">
    <template #frame-header-fixed>
      <!-- 검색결과 -->
      <ur-box-container v-if="showReturnStr && !isInit" alignV="start"  direction="column" class="search-result-box bd-b-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" v-show="lv_ReturnStr[0] !== ''" @click="fn_OpenMSPCM116P">
            <span class="txt">{{lv_ReturnStr[0]}}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>
      <!-- 전체체크 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll()" :disabled="isEmptyView" size="small" />
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum" v-if="checkCount === 0">총 <strong class="crTy-blue3">{{ totCnt }}</strong> 명</span>
              <span class="sum" v-else><strong class="crTy-blue3">{{ checkCount }}</strong> 명 선택</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container v-if="!isEmptyView" direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list">  
          <mo-list :list-data="rCCnsltBefrCndtrListVO">
            <template #list-item="{index, item}">
              <mo-list-item :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItemCount(item)" :ref="`chk${index}`"/>
                <div class="list-item__contents">
                  <!-- 이름, 참석여부 -->
                  <div class="list-item__contents__title">
                    <span class="name txtSkip" @click.stop="fn_OpenCustInfoBs(item)">{{item.custNm}}</span>
                    <mo-badge v-if="!$bizUtil.isEmpty(item.semYmd)" class="badge-sample-badge lightblue sm" text="참석" shape="status">참석</mo-badge>
                    <mo-badge v-else class="badge-sample-badge lightred sm" text="미참석" shape="status">미참석</mo-badge>
                  </div>
                  <!-- 생년월일, 성별 -->
                  <div class="list-item__contents__info">
                    <span>{{$commonUtil.dateDashFormat(item.dob)}}</span><em>|</em><span>{{item.sxdsCdNm}}</span>
                  </div>
                  <!-- 세미나명 -->
                  <div class="list-item__contents__info">
                    <span class="txtSkip2 align-item-start">{{item.semNm}}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="crTy-bk7 fs14rem mr10">세미나일자</span><span>{{$commonUtil.dateDashFormat(item.semPlnYmd)}}</span>
                  </div>
                  <div class="list-item__contents__info" v-if="!$bizUtil.isEmpty(item.semYmd)">
                    <span class="crTy-bk7 fs14rem mr10">참석일</span><span>{{$commonUtil.dateDashFormat(item.semYmd)}}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span>리크루팅 동의 <b class="crTy-blue2 pl10" v-if="item.cndtrCnsntRmdrDds === 'Y'">Y</b><b class="crTy-orange2 pl10" v-else>N</b></span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>  
      </ur-box-container>
    </ur-box-container>

    <!-- NoData 영역  -->
    <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>

    <!-- 리크루팅동의, 삭제 -->
    <mo-bottom-sheet ref="bottomSheetApl" :close-btn="false" :class="positionUp ? 'ns-bottom-sheet ns-style3 only-btn positionUp' : 'ns-bottom-sheet ns-style3 only-btn'" noHeader>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_MoveMSPRC100M" :disabled="checkCount > 1" class="bgcolor-2 bd-radius-6 blue">리크루팅동의</mo-button>
            <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_DeleteSem" class="bgcolor-2 bd-radius-6 blue">삭제</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- Toast -->
    <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

    <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
    <div v-if="positionUp && rCCnsltBefrCndtrListVO.length > 0" class="ns-height120"></div>
    <div v-else-if="rCCnsltBefrCndtrListVO.length > 0" class="ns-height40"></div>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPCM116P from '@/ui/cm/MSPCM116P' // 고객검색
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCM201M",
  screenId: "MSPCM201M",
  components: {
    MSPCM116P,
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

    // 세미나활동 리스트 조회
    this.fn_Submit()
  },

  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  watch: {
    checkCount () {
      if (this.checkCount === this.rCCnsltBefrCndtrListVO.length && this.rCCnsltBefrCndtrListVO.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  computed: {
    selectItems() {
      return this.rCCnsltBefrCndtrListVO.filter(item => item.checked)
    },
    checkCount () {
      return this.rCCnsltBefrCndtrListVO.filter(item => item.checked).length
    },
    showReturnStr () {
      if (this.isMngr && !_.isEmpty(this.pOrgData201M)) {
        return true
      }
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true
        }
      }
      return false
    },
    topButtonBottom () {
      // topButton을 positionUp 클래스가 적용된 바텀시트의 위쪽으로 올라오게 함
      if (this.positionUp) return 150
      else return 0
    }
  },

  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    this.fn_InitBottomSheet()
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      toastMsg: '', // toast 문구
      pOrgData201M: {}, // 최근 조직 조회 데이터

      // 체크 변수
      isSearched: false,  // 검색 실행 여부
      isInit: false, // 검색 초기화 여부
      isOneOrMoreCheck: false, // 전체선택
      positionUp: false, // 
      isCustCardExist: true, // 채널아이디 여부

      // 팝업 연동
      popup116: {}, // MSPCM116P 고객검색
      lv_ReturnStr: [], // 상세조회조건 텍스트 배열
      
      // 조회 변수
      // 세미나활동 리스트 조회
      rCCnsltBefrCndtrListVO: [], // 인피니트 바인딩 리스트VO
      userInfo: {}, // 사용자정보 obj
      isLoading: false,
      isEmptyView: false, // 데이터 없음 화면
      lv_cnsltNo: '', // 컨설턴트 번호
      lv_custNm: '',  // 검색한 고객명
      totCnt: 0,
      // 페이징
      nextKey: 0,
      stndKeyVal: '',
    };
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      this.$refs[`chk${index}`].onClick()
    },

    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (this.isSearched) {
          // 조회를 한번이라도 한 경우 전체 검색
          // 초기화
          this.pOrgData201M = {}  // 조직 조회 데이터
          this.isSearched = false // 검색이력
          this.isInit = true
          this.lv_custNm = ''
          this.fn_InitBottomSheet() // 바텀시트
  
          // 재조회
          this.fn_Submit()
        } else {
          // 조회를 하지 않았으면 뒤로가기
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          this.$router.go(-1)
        }
      }
      
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      let lv_Vm = this

      this.$nextTick(() => {
        if (lv_Vm.rCCnsltBefrCndtrListVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in lv_Vm.rCCnsltBefrCndtrListVO) {
            if (lv_Vm.isOneOrMoreCheck) {
              lv_Vm.rCCnsltBefrCndtrListVO[n].checked = true
            } else {
              lv_Vm.rCCnsltBefrCndtrListVO[n].checked = false
            }
          }
          this.fn_CheckSelectedItemCount()
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다.
     ******************************************************************************/
    fn_CheckSelectedItemCount (custInfo) {
      let lv_Vm = this

      // 체크 시 BottomSheet 컨트롤
      // 공통 시트를 가리고 사용자 시트를 보여준다.
      this.$cmUtil.setSelectedCustList(this, this.selectItems)
      if (lv_Vm.checkCount > 0) {
        lv_Vm.positionUp = true
        lv_Vm.$refs.bottomSheetApl.open()   // 리크루팅 동의, 삭제 버튼 바텀시트
      } else {
        lv_Vm.fn_InitBottomSheet()
      }
    },

    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet () {
      let lv_Vm = this

      // 바텀시트 초기화
      if (lv_Vm.$refs !== undefined) {
        if (lv_Vm.$refs.bottomSheetApl !== undefined) {
          lv_Vm.positionUp = false
          lv_Vm.$refs.bottomSheetApl.close()
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {
      let lv_Vm = this

      // 체크된 고객 바텀시트 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)
      if (lv_Vm.$refs.bottomSheetApl !== undefined) {
        lv_Vm.positionUp = false
        lv_Vm.$refs.bottomSheetApl.close()
      }

      lv_Vm.stndKeyVal = ''
      lv_Vm.totCnt = 0
      lv_Vm.rCCnsltBefrCndtrListVO = [] // 인피니트 바인딩 데이터
      lv_Vm.isEmptyView = false // 데이터가 없습니다 visible 여부

      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      lv_Vm.fn_GetService() // 서비스 호출
    },

    /******************************************************************************
     * Function명 : fn_GetService
     * 설명       : 사전후보자 목록을 조회한다.
     ******************************************************************************/
    fn_GetService () {
      if (_.isEmpty(this.lv_cnsltNo)) {
        this.getStore('confirm').dispatch('ADD', this.$t('rc')['ERCC002']) // 컨설턴트 정보가 없습니다.
        return
      }
      var lv_Vm = this
      // const trnstId = 'txTSSRC01S1'
      const trnstId = 'txTSSCM49S7'
      const auth = 'S'
      let pParams = {}
      pParams.cnstlNo = this.lv_cnsltNo // 컨설턴트사번

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response && response.body) {
            let list = response.body.rCCnsltBefrCndtrListVO
            if (!_.isEmpty(list)) {
              for ( let i = 0; i < list.length; i++) {
                let t_VO = list[i]
                let tmp = t_VO

                // 체크
                tmp.checked = false
                // 리크루팅 동의: Y/N
                if (!_.isEmpty(t_VO.cndtCnsntEndYmd.trim())) tmp.cndtrCnsntRmdrDds = 'Y'
                else tmp.cndtrCnsntRmdrDds = 'N'
                
                lv_Vm.rCCnsltBefrCndtrListVO.push(tmp)
              }
              
              if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.lv_custNm)) { // 조회조건 고객명이 있으면 필터링
                let regex = new RegExp(`^${lv_Vm.lv_custNm}`)
                lv_Vm.rCCnsltBefrCndtrListVO = lv_Vm.rCCnsltBefrCndtrListVO.filter(item => regex.test(item.custNm))
              }

              lv_Vm.totCnt = lv_Vm.rCCnsltBefrCndtrListVO.length
            }
            if (lv_Vm.totCnt === 0) lv_Vm.isEmptyView = true
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          if (!_.isEmpty(error.message)) {
            lv_Vm.getStore('confirm').dispatch('ADD', error.message)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('rc')['ERCC001']) // 시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.
          }
        })
    },

    /******************************************************************************
      * Function명 : fn_DeleteSem
      * 설명       : 세미나 삭제
    ******************************************************************************/
    fn_DeleteSem () {
      var lv_Vm = this
      if (lv_Vm.selectItems[0].cndtrCnsntRmdrDds === 'Y') {
        lv_Vm.getStore('confirm').dispatch('ADD', '리크루팅 동의 완료 고객은 삭제 할 수 없습니다.') //ERCC003, 미니 프로젝트 내 rc 업무구분이 없어 하드코딩
        return
      }
      if (!this.$bizUtil.isEmpty(lv_Vm.selectItems[0].semYmd)) {
        lv_Vm.getStore('confirm').dispatch('ADD', '세미나 참석한 고객은 삭제 할 수 없습니다.') // ERCC004
        return
      }

      const trnstId = 'txTSSRC02I1'
      const auth = 'S'
      let newCustList = []
      lv_Vm.selectItems.forEach((item, index) => {
        let vo = {}
        vo.chnlCustId = item.chnlCustId // 채널고객ID
        vo.regYn = 'N' // 등록여부
        newCustList.push(vo)
      })
      let pParams = {}
      pParams.cnstlNo = lv_Vm.userInfo.cnsltNo // 추천컨설턴트사번
      pParams.semTypCd = '' // 세미나유형코드 조회 목록에 없음 공백으로넘긴다
      pParams.dofOrgNo = lv_Vm.userInfo.onpstDofNo // 세미나지역단조직번호
      pParams.semPlnYmd = lv_Vm.selectItems[0].semPlnYmd // 세미나일자
      pParams.rCPreCandidateVO = newCustList // 사전후보자LIST

      lv_Vm.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response && response.body) {
            lv_Vm.getStore('confirm').dispatch('ADD', '삭제 되었습니다.') // ECOC013
            // 세미나활동 리스트 조회
            lv_Vm.fn_Submit()
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          if (!_.isEmpty(error.message)) {
            lv_Vm.getStore('confirm').dispatch('ADD', error.message)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '시스템 오류가 발생했습니다. 관리자에게 문의하여 주세요.') // ERCC001
          }
        })
    },

    /******************************************************************************
     * Function명 : fn_MoveMSPRC100M
     * 설명       : 리크루팅동의 메인화면으로 이동
     ******************************************************************************/
    fn_MoveMSPRC100M () {
      this.$router.push({name: 'MSPRC100M'})
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM116P
     * 설명       : 고객검색 팝업 호출
     ******************************************************************************/
    fn_OpenMSPCM116P () {
      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pPage: this.$options.screenId,
          pOrgData: this.pOrgData201M, // 최근 조직 조회 데이터
          pSearchKeyword: this.lv_custNm // 고객명
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 파라미터 세팅
            this.lv_custNm = pData.rtnData.searchKeyword
            // 검색 실행 여부
            this.isSearched = true
            // 검색 초기화 여부
            this.isInit = pData.isInit
            if (this.isInit) this.isSearched = false
            // 조회조건 텍스트 세팅
            this.lv_ReturnStr = pData.srchOpt
            // 재조회
            this.fn_Submit()

            this.$bottomModal.close(this.popup116)
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}

      if(this.pOrgData201M.cnslt === undefined || this.pOrgData201M.cnslt === null || this.pOrgData201M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgData201M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : 'MSPCM201M', //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.custNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.isSearched) {
                // 조회를 한번이라도 한 경우 전체 검색
                // 초기화
                this.pOrgData201M = {}  // 조직 조회 데이터
                this.isSearched = false // 검색이력
                this.isInit = true
                this.lv_custNm = ''
                this.fn_InitBottomSheet() // 바텀시트

                // 재조회
                this.fn_Submit()
              } else {
                // 조회를 하지 않았으면 뒤로가기
                if (this.$route.query?._isMenuItem) {
                  this.$router.push({name: 'MSPBC002M'})
                  return
                }
                this.$router.go(-1)
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
  }
};
</script>
<style scoped>
</style>